<template>
  <div>
    <div class="form-group">
      <div class="question">
        <!-- Question Group Name -->
        <h2>{{ question.groupName }}</h2>

        <!-- Question Description -->
        <p class="mt-3" v-show="question.questionDescription">
          {{ question.questionDescription }}
        </p>

        <!-- Question Sub Group Name -->
        <p class="personal-info mt-3" v-show="question.subGroupName">
          <span v-if="queIdExistsInSubGroupName">{{
            getSubGroupNameWithName
          }}</span>
          <span v-else>{{ question.subGroupName }}</span>
        </p>
      </div>
      <div class="row">
        <template v-for="(que, index) in theQuestions">
          <transition :key="index" name="fade">
            <template v-if="checkLocalDependancy(que)">
              <div :class="que.questionCSSClass">
                <form @submit.prevent>
                  <div class="form-group">
                    <div
                      v-if="
                        que.questionFieldMapping === 'dependent_age__c' ||
                        que.questionFieldMapping ===
                          'candidoem__dependent_age__c' ||
                        que.questionFieldMapping === 'co_dependent_age__c' ||
                        que.questionFieldMapping ===
                          'candidoem__co_dependent_age__c'
                      "
                    >
                      <template v-for="(n, index) in numberOfDependants">
                        <div :key="index" :class="index > 0 ? 'mt-3' : ''">
                          <label :for="que.questionText" class="form-label"
                            >{{ "Dependent " + (index + 1) + " Age" }}
                            <small v-if="que.isMandatory" class="asterisk-mark"
                              >*</small
                            >
                          </label>
                          <input
                            type="number"
                            step="1"
                            min="0"
                            pattern="\d*"
                            inputmode="numeric"
                            class="form-control"
                            :id="'Dependent ' + (index + 1) + ' Age'"
                            v-model.trim="theDependantAges[index]"
                            :placeholder="que.questionText + ' ' + (index + 1)"
                            @keydown="decimalValidation($event)"
                            @input="onlyNumberInDependantAges($event, index)"
                          />
                        </div>
                      </template>
                    </div>

                    <div v-else>
                      <!-- Label for Input Types -->
                      <label :for="que.questionText" class="form-label"
                        >{{ que.questionText }}
                        <small v-if="que.isMandatory" class="asterisk-mark"
                          >*</small
                        >
                      </label>

                      <!-- Type: Picklist OR Checkbox -->
                      <template
                        v-if="
                          que.questionType === 'Picklist' ||
                          que.questionType === 'Checkbox'
                        "
                      >
                        <div
                          class="answer"
                          v-if="
                            que.questionFieldMapping ===
                              'citizenship_status_b__c' ||
                            que.questionFieldMapping ===
                              'candidoem__citizenship_status_b__c' ||
                            que.questionFieldMapping ===
                              'citizenship_status_co__c' ||
                            que.questionFieldMapping ===
                              'candidoem__citizenship_status_co__c'
                          "
                        >
                          <ul
                            :class="
                              que.questionOptions.length === 3 ? 'flex-3' : ''
                            "
                          >
                            <li
                              v-for="(option, ind) in que.questionOptions"
                              :key="ind"
                            >
                              <input
                                v-if="que.questionType === 'Picklist'"
                                type="radio"
                                :id="option.optionValue + que.questionId"
                                :value="option.optionValue"
                                v-model.trim="theAnswers[index]"
                              />
                              <label :for="option.optionValue + que.questionId">
                                <img
                                  v-if="option.optionSvgLink"
                                  class="dynamic-color-icons"
                                  :src="option.optionSvgLink"
                                />
                                <span>{{ option.optionName }}</span>
                              </label>
                            </li>
                          </ul>
                        </div>

                        <div class="answer" v-else>
                          <div class="d-flex">
                            <div
                              class="small-radio pe-3"
                              v-for="(option, i) in que.questionOptions"
                              :key="i"
                            >
                              <input
                                v-if="que.questionType === 'Picklist'"
                                type="radio"
                                :id="option.optionValue + que.questionId"
                                :value="option.optionValue"
                                v-model.trim="theAnswers[index]"
                              />
                              <input
                                v-if="que.questionType === 'Checkbox'"
                                type="radio"
                                :id="option.optionValue + que.questionId"
                                :value="
                                  option.optionValue === 'Yes' ? true : false
                                "
                                v-model.trim="theAnswers[index]"
                              />
                              <label :for="option.optionValue + que.questionId">
                                <img
                                  v-if="option.optionSvgLink"
                                  class="dynamic-color-icons"
                                  :src="option.optionSvgLink"
                                />
                                <span>{{ option.optionName }}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </template>

                      <!-- Type: Dropdown -->
                      <template v-else-if="que.questionType === 'Dropdown'">
                        <select
                          class="form-select"
                          v-model.trim="theAnswers[index]"
                          @change="
                            que.questionFieldMapping ===
                              'number_of_dependents__c' ||
                            que.questionFieldMapping ===
                              'candidoem__number_of_dependents__c' ||
                            que.questionFieldMapping ===
                              'co_number_of_dependents__c' ||
                            que.questionFieldMapping ===
                              'candidoem__co_number_of_dependents__c'
                              ? updateTheDependants()
                              : ''
                          "
                        >
                          <option
                            v-for="(option, index) in que.questionOptions"
                            :key="index"
                          >
                            {{ option.optionName }}
                          </option>
                        </select>
                      </template>

                      <!-- Type: Input Fields -->
                      <template v-else>
                        <div
                          v-if="
                            que.questionFieldMapping === 'ltv__c' ||
                            que.questionFieldMapping === 'candidoem__ltv__c'
                          "
                        >
                          <div class="input-group two-input-group">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text"
                                :id="'basic-addon' + index"
                                ><i
                                  :class="
                                    que.questionType === 'Percent'
                                      ? getIcons('Currency')
                                      : ''
                                  "
                                ></i
                              ></span>
                              <input
                                type="number"
                                :inputmode="getInputMode(que)"
                                class="form-control"
                                v-model="downPaymentAmount"
                              />
                            </div>
                            <div class="input-group-append percentage">
                              <div class="input-group">
                                <input
                                  type="text"
                                  inputmode="decimal"
                                  max="100"
                                  maxlength="5"
                                  class="standard-text input-group-text"
                                  v-model.trim="theAnswers[index]"
                                />
                                <div class="input-group-append">
                                  <span class="input-group-text bg-transparent"
                                    ><i :class="getIcons(que.questionType)"></i
                                  ></span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <small class="text-red" v-if="invalidDownPayment">
                            Down Payment must not be greater than Purchase
                            Price.
                          </small>
                          <p class="mt-2">
                            Loan Amount:
                            <strong>${{ loanAmount | formatNumber }}</strong>
                          </p>
                        </div>

                        <div
                          v-else-if="
                            inputFieldsWithIcons.includes(que.questionType)
                          "
                          class="input-group two-input-group"
                        >
                          <div class="input-group-prepend">
                            <span
                              class="input-group-text"
                              :id="'basic-addon' + index"
                              ><i :class="getIcons(que.questionType)"></i
                            ></span>
                          </div>
                          <input
                            :type="getFieldType(que)"
                            :inputmode="getInputMode(que)"
                            class="form-control"
                            :id="que.questionText"
                            :placeholder="que.questionText"
                            v-model.trim="theAnswers[index]"
                            @blur="
                              que.questionFieldMapping ===
                                'social_security_number__c' ||
                              que.questionFieldMapping ===
                                'candidoem__social_security_number__c' ||
                              que.questionFieldMapping ===
                                'co_social_security_number__c' ||
                              que.questionFieldMapping ===
                                'candidoem__co_social_security_number__c'
                                ? maskSecurityNumber($event, index)
                                : ''
                            "
                            @input="
                              que.questionFieldMapping ===
                                'social_security_number__c' ||
                              que.questionFieldMapping ===
                                'candidoem__social_security_number__c' ||
                              que.questionFieldMapping ===
                                'co_social_security_number__c' ||
                              que.questionFieldMapping ===
                                'candidoem__co_social_security_number__c'
                                ? maskSecurityNumber($event, index)
                                : ''
                            "
                          />
                          <div
                            class="input-group-append"
                            v-if="
                              getFieldType(que) === 'password' ||
                              getFieldType(que) === 'show-password'
                            "
                          >
                            <span
                              @click="showInput(index)"
                              class="input-group-text input-group-eye bg-transparent"
                            >
                              <i
                                :class="
                                  getFieldType(que) === 'show-password'
                                    ? 'far fa-eye'
                                    : 'fas fa-eye-slash'
                                "
                              ></i>
                            </span>
                          </div>
                        </div>

                        <input
                          v-else
                          :type="getFieldType(que)"
                          :inputmode="getInputMode(que)"
                          class="form-control"
                          @blur="
                            que.questionType === 'Phone'
                              ? maskNumber($event, index)
                              : ''
                          "
                          :id="que.questionText"
                          :placeholder="que.questionText"
                          v-model.trim="theAnswers[index]"
                          @input="
                            que.questionType === 'Email'
                              ? checkEmail(theAnswers[index])
                              : que.questionType === 'Phone'
                              ? maskNumber($event, index)
                              : ''
                          "
                        />

                        <!-- Error Messages -->
                        <small
                          class="text-danger"
                          v-if="que.questionType === 'Email' && !validEmail"
                        >
                          Please enter a valid email address.</small
                        >
                        <small
                          class="text-danger"
                          v-if="
                            que.questionType === 'Phone' && !validPhoneNumber
                          "
                        >
                          Please enter a 10 digit mobile number.</small
                        >
                        <template
                          v-if="
                            que.questionFieldMapping ===
                              'social_security_number__c' ||
                            que.questionFieldMapping ===
                              'candidoem__social_security_number__c' ||
                            que.questionFieldMapping ===
                              'co_social_security_number__c' ||
                            que.questionFieldMapping ===
                              'candidoem__co_social_security_number__c'
                          "
                        >
                          <small
                            class="text-danger"
                            v-if="!validSecurityNumber"
                          >
                            Please enter a 9 digit Social Security Number
                          </small>
                          <small class="text-danger" v-else-if="!uniqueSSN">
                            Applicant's SSN must not be same as Co-applicant's
                            SSN
                          </small>
                        </template>
                      </template>

                      <div
                        v-if="
                          (que.questionFieldMapping ===
                            'citizenship_renewal_date__c' ||
                            que.questionFieldMapping ===
                              'candidoem__citizenship_renewal_date__c' ||
                            que.questionFieldMapping ===
                              'co_citizenship_renewal_date__c' ||
                            que.questionFieldMapping ===
                              'candidoem__co_citizenship_renewal_date__c') &&
                          !theAnswers[index]
                        "
                        class="mt-3 form-group"
                      >
                        <span>
                          <div class="checkbox border-checkbox">
                            <input
                              type="checkbox"
                              id="citizenship-status"
                              v-model="citizenshipStatus"
                            />
                            <label for="citizenship-status">I don't know</label>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </template>
          </transition>
        </template>
      </div>
    </div>
    <div class="button mt-4 justify-content-between">
      <div class="d-flex">
        <button type="button" class="btn btn-outline" @click="previousQuestion">
          <span>Previous</span>
        </button>
        <button
          type="button"
          class="btn"
          :class="checkTheValidation ? 'btn-red' : ''"
          :disabled="!checkTheValidation"
          @click="saveTheData"
        >
          <span>Continue</span>
        </button>
      </div>

      <div class="align-text-right col-sm-6">
        <a
          v-if="isInfoLinkExist"
          :data-bs-toggle="
            theQuestions[getInfoLinkQueId].questionLink &&
            theQuestions[getInfoLinkQueId].questionLinkDescription
              ? 'modal'
              : ''
          "
          data-bs-target="#infoModal"
          class="text-underline"
          >{{ theQuestions[getInfoLinkQueId].questionLink }}</a
        >
      </div>
      <info-modal
        v-if="isInfoLinkExist"
        :title="theQuestions[getInfoLinkQueId].questionLink"
        :description="theQuestions[getInfoLinkQueId].questionLinkDescription"
      />
    </div>
  </div>
</template>

<script>
import commonFunctions from "./../../mixins/commonfunctions";
import InfoModal from "../InfoModal.vue";
import moment from "moment";

export default {
  name: "FormFields",
  mixins: [commonFunctions],
  components: { InfoModal },
  data() {
    return {
      theQuestions: [],
      theAnswers: [],
      theDependantAges: [],
      downPaymentAmount: "",
      purchasePrice: "",
      preventNextIteration: false,
      citizenshipStatus: false,
    };
  },
  props: {
    question: {
      required: true,
      type: Object,
    },
    currentaq: {
      required: true,
      type: Number,
    },
    previousaq: {
      required: true,
      type: Number,
    },
  },
  watch: {
    theAnswers: {
      immediate: true,
      handler(newVal) {
        let getPPIndex = this.theQuestions.findIndex(
          (v) =>
            v.questionFieldMapping === "purchase_price__c" ||
            v.questionFieldMapping === "candidoem__purchase_price__c"
        );

        let getDPIndex = this.theQuestions.findIndex(
          (v) =>
            v.questionFieldMapping === "ltv__c" ||
            v.questionFieldMapping === "candidoem__ltv__c"
        );

        let purchasePrice = Number(newVal[getPPIndex]);
        let downPaymentPercent = Number(newVal[getDPIndex]);

        if (purchasePrice || downPaymentPercent) {
          this.downPaymentAmount = (
            (purchasePrice * downPaymentPercent) /
            100
          ).toFixed(0);
        } else {
          this.downPaymentAmount = "";
        }
      },
    },

    downPaymentAmount(newVal) {
      let getPPIndex = this.theQuestions.findIndex(
        (v) =>
          v.questionFieldMapping === "purchase_price__c" ||
          v.questionFieldMapping === "candidoem__purchase_price__c"
      );

      let getDPIndex = this.theQuestions.findIndex(
        (v) =>
          v.questionFieldMapping === "ltv__c" ||
          v.questionFieldMapping === "candidoem__ltv__c"
      );

      if (newVal) {
        let purchasePrice = Number(this.theAnswers[getPPIndex]);

        if (newVal > purchasePrice) this.theAnswers[getDPIndex] = String(0);
        else {
          let percentageValue = (newVal * 100) / purchasePrice;

          percentageValue = isNaN(percentageValue) ? 0 : percentageValue;
          this.theAnswers[getDPIndex] = String(percentageValue);
        }
      }

      if (!newVal) {
        this.downPaymentAmount = "";
        this.theAnswers[getDPIndex] = String(0);
      }
    },

    theDependantAges(newVal) {
      let theIndex = this.theQuestions.findIndex(
        (v) =>
          v.questionFieldMapping === "dependent_age__c" ||
          v.questionFieldMapping === "candidoem__dependent_age__c" ||
          v.questionFieldMapping === "co_dependent_age__c" ||
          v.questionFieldMapping === "candidoem__co_dependent_age__c"
      );

      if (newVal.length) this.theAnswers[theIndex] = newVal.toString();
      else this.theAnswers[theIndex] = "None";
    },

    citizenshipStatus(newVal) {
      if (!newVal) {
        let getIndex = this.theQuestions.findIndex(
          (x) =>
            x.questionFieldMapping === "citizenship_renewal_date__c" ||
            x.questionFieldMapping ===
              "candidoem__citizenship_renewal_date__c" ||
            x.questionFieldMapping === "co_citizenship_renewal_date__c" ||
            x.questionFieldMapping ===
              "candidoem__co_citizenship_renewal_date__c"
        );

        if (getIndex) this.theAnswers[getIndex] = "";
      }
    },
  },
  computed: {
    isCitizenShipRenewal() {
      let getIndex = this.theQuestions.findIndex(
        (x) =>
          x.questionFieldMapping === "citizenship_renewal_date__c" ||
          x.questionFieldMapping === "candidoem__citizenship_renewal_date__c" ||
          x.questionFieldMapping === "co_citizenship_renewal_date__c" ||
          x.questionFieldMapping === "candidoem__co_citizenship_renewal_date__c"
      );

      if (this.theAnswers[getIndex]) return true;

      return this.citizenshipStatus ? true : false;
    },

    isInfoLinkExist() {
      return this.theQuestions.some(
        (v) => v.questionLink !== null && v.questionLinkDescription !== null
      );
    },

    getInfoLinkQueId() {
      return this.theQuestions.findIndex(
        (v) => v.questionLink !== null && v.questionLinkDescription !== null
      );
    },

    checkTheValidation() {
      let theArr = [];
      let theAges = [];
      let ques = this.theQuestions;
      let answers = this.theAnswers;

      for (let i = 0; i < ques.length; i++) {
        if (this.checkLocalDependancy(ques[i])) {
          if (
            ques[i].isMandatory &&
            (answers[i] === undefined ||
              answers[i] === "" ||
              answers[i] === null)
          ) {
            if (!this.isCitizenShipRenewal) theArr.push(true);
            else theArr.push(false);
          } else if (
            ques[i].questionType === "Date" &&
            answers[i] === "Invalid date"
          )
            theArr.push(true);
          else theArr.push(false);
        }
      }

      if (this.numberOfDependants > 0) {
        for (let j = 0; j < this.numberOfDependants; j++) {
          if (
            this.theDependantAges[j] === undefined ||
            this.theDependantAges[j] === "" ||
            this.theDependantAges[j] === null
          )
            theAges.push(true);
          else theAges.push(false);
        }
      }

      return (
        theArr.every((v) => v === false) &&
        this.validEmail &&
        this.validPhoneNumber &&
        this.validSecurityNumber &&
        this.uniqueSSN &&
        !this.invalidDownPayment &&
        theAges.every((v) => v === false)
      );
    },

    invalidDownPayment() {
      let getPPIndex = this.theQuestions.findIndex(
        (v) =>
          v.questionFieldMapping === "purchase_price__c" ||
          v.questionFieldMapping === "candidoem__purchase_price__c"
      );

      if (getPPIndex || getPPIndex === 0) {
        let purchasePrice = Number(this.theAnswers[getPPIndex]);

        if (this.downPaymentAmount > purchasePrice) return true;
        else return false;
      }

      return false;
    },

    loanAmount() {
      let getPPIndex = this.theQuestions.findIndex(
        (v) =>
          v.questionFieldMapping === "purchase_price__c" ||
          v.questionFieldMapping === "candidoem__purchase_price__c"
      );

      let purchasePrice = Number(this.theAnswers[getPPIndex]);

      if (this.downPaymentAmount > purchasePrice) return 0;

      return purchasePrice - this.downPaymentAmount || 0;
    },

    numberOfDependants() {
      let theIndex = this.theQuestions.findIndex(
        (v) =>
          v.questionFieldMapping === "number_of_dependents__c" ||
          v.questionFieldMapping === "candidoem__number_of_dependents__c" ||
          v.questionFieldMapping === "co_number_of_dependents__c" ||
          v.questionFieldMapping === "candidoem__co_number_of_dependents__c"
      );

      return Number(this.theAnswers[theIndex]) || 0;
    },
  },
  created() {
    this.unAuthorizedAccess(this.question);
  },
  beforeMount() {
    this.mergeTheQuestions();
  },
  mounted() {
    this.checkTheDependancy(this.question);
  },
  methods: {
    updateTheDependants() {
      this.theDependantAges = [];
    },

    decimalValidation(event) {
      const key = event.key;
      if (key === ".") return event.preventDefault();
      // If is 'e' key, stop it
      if (key === "e") return event.preventDefault();
    },

    onlyNumberInDependantAges(event, index) {
      this.theDependantAges[index] = event.target.value.replace(/[^0-9]+/g, "");
    },

    maskNumber(event, index) {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.preventNextIteration = true;
        return;
      }

      if (this.preventNextIteration) {
        this.preventNextIteration = false;
        return;
      }

      if (this.theAnswers[index] && this.theAnswers[index].length) {
        let x = this.theAnswers[index]
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

        this.theAnswers[index] =
          x[1] + (x[2] ? "-" + x[2] : "") + (x[3] ? "-" + x[3] : "");
      }

      this.checkPhoneNumber(this.theAnswers[index]);
    },

    maskSecurityNumber(event, index) {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.preventNextIteration = true;
        return;
      }

      if (this.preventNextIteration) {
        this.preventNextIteration = false;
        return;
      }

      if (this.theAnswers[index].length) {
        let x = this.theAnswers[index]
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,2})(\d{0,4})/);

        // Format display value based on calculated currencyValue
        this.theAnswers[index] =
          x[1] + (x[2] ? "-" + x[2] : "") + (x[3] ? "-" + x[3] : "");
      }

      this.checkSecurityNumber(this.theAnswers[index]);
      this.checkDuplicateSSN(this.theAnswers[index]);
    },

    checkLocalDependancy(que) {
      if (que.dependentQuestionId) {
        let checkLocal = this.getQuestions.find(
          (m) => m.questionId === que.dependentQuestionId
        );
        if (checkLocal) {
          if (checkLocal.groupName === que.groupName) {
            let getTheIndex = this.theQuestions.findIndex(
              (v) => v.questionId === que.dependentQuestionId
            );

            if (
              getTheIndex > -1 &&
              this.theQuestions[getTheIndex].questionType === "Checkbox" &&
              que.dependentQuestionAnswer ===
                (this.theAnswers[getTheIndex] === true ? "Yes" : "No")
            ) {
              return true;
            } else if (
              getTheIndex > -1 &&
              que.dependentQuestionAnswer === this.theAnswers[getTheIndex]
            ) {
              return true;
            } else {
              let getQueIndex = this.theQuestions.findIndex(
                (v) => v.questionId === que.questionId
              );
              if (getQueIndex) this.theAnswers[getQueIndex] = "";
              return false;
            }
          } else {
            if (checkLocal.questionAnswer === que.dependentQuestionAnswer)
              return true;
            else return false;
          }
        }
      } else {
        return true;
      }
    },

    showInput(index) {
      let queType = this.theQuestions[index].questionType;
      this.theQuestions[index].questionType =
        queType === "Password" ? "Show-Password" : "Password";
    },

    async saveTheData() {
      this.fetchCompLoaderStatus(true);
      let que = this.question;

      this.theQuestions.forEach((v, i) => {
        if (
          v.questionFieldMapping === "firstname__c" ||
          v.questionFieldMapping === "candidoem__firstname__c" ||
          v.questionFieldMapping === "lastname__c" ||
          v.questionFieldMapping === "candidoem__lastname__c" ||
          v.questionFieldMapping === "first_name_co_borrower__c" ||
          v.questionFieldMapping === "candidoem__first_name_co_borrower__c" ||
          v.questionFieldMapping === "last_name_co_borrower__c" ||
          v.questionFieldMapping === "candidoem__last_name_co_borrower__c"
        ) {
          v.questionAnswer = this.capitalizeFirstChar(this.theAnswers[i]);
        } else if (v.questionType === "Checkbox") {
          v.questionAnswer = this.theAnswers[i] ? "true" : "false";
        } else {
          v.questionAnswer = this.theAnswers[i];
        }
      });

      let payLoad = {};

      // payLoad for Vuex
      payLoad = {
        questions: this.theQuestions,
      };

      this.addMultipleAnswersLocally(payLoad); // stores locally

      // API for other form fields
      if (this.getUserId && que.apiName === "LockLearProspectAuth") {
        // payLoad for API
        payLoad = {
          prospectId: this.getUserId,
          questionsToUpdate: this.theQuestions,
          orgId: this.getOrgId,
        };

        await this.$http
          .post("/question/prospect/Questions/", payLoad)
          .then((response) => {
            let resp = response.data;

            if (resp.response === "Success") {
              this.updateQueObjectWithAnswer(resp.appQuestions);
              this.nextQuestion();
            }

            if (resp.response === "Error") this.err(resp.message);
          })
          .catch((error) => {
            this.err(error);
            console.log(error);
          });
      }

      if (!this.getUserId) this.nextQuestion();

      this.fetchCompLoaderStatus(false);
    },

    nextQuestion() {
      if (
        this.getActiveQuestion + 1 > this.getQuestions.length &&
        !this.getEditModeFlag
      )
        this.$router.push("/submit-application");

      if (this.getActiveQuestion <= this.getQuestions.length)
        this.fetchActiveQuestion(this.theQuestions.at(-1).questionIndex + 1);
    },

    basicDecrement() {
      this.fetchActiveQuestion(this.theQuestions[0].questionIndex - 1);
    },

    mergeTheQuestions() {
      let theFields = [];
      theFields.push(this.question);

      for (let i = this.getActiveQuestion; i < this.getQuestions.length; i++) {
        if (
          this.getQuestions[i].groupName === null ||
          this.getQuestions[i].groupName !== this.question.groupName
        ) {
          break;
        }

        if (
          this.getQuestions[i].questionFieldMapping ===
            "social_security_number__c" ||
          this.getQuestions[i].questionFieldMapping ===
            "candidoem__social_security_number__c" ||
          this.getQuestions[i].questionFieldMapping ===
            "co_social_security_number__c" ||
          this.getQuestions[i].questionFieldMapping ===
            "candidoem__co_social_security_number__c"
        ) {
          this.getQuestions[i].questionType = "Show-Password";
        }

        theFields.push(this.getQuestions[i]);
      }

      this.theQuestions = theFields;

      if (theFields.length) {
        this.theAnswers = theFields.map((v) => {
          if (v.questionType === "Date") {
            return v.questionAnswer
              ? moment(v.questionAnswer).format("YYYY-MM-DD")
              : v.defaultQuestionAnswer
              ? moment(v.defaultQuestionAnswer).format("YYYY-MM-DD")
              : undefined;
          } else if (v.questionType === "Checkbox") {
            return v.questionAnswer === null || v.questionAnswer === ""
              ? v.defaultQuestionAnswer
                ? v.defaultQuestionAnswer === "false"
                  ? false
                  : true
                : undefined
              : v.questionAnswer === "false"
              ? false
              : true;
          } else if (
            v.questionFieldMapping === "dependent_age__c" ||
            v.questionFieldMapping === "candidoem__dependent_age__c" ||
            v.questionFieldMapping === "co_dependent_age__c" ||
            v.questionFieldMapping === "candidoem__co_dependent_age__c"
          ) {
            this.theDependantAges = v.questionAnswer
              ? v.questionAnswer.split(",")
              : [];
            return v.questionAnswer ? v.questionAnswer.split(",") : undefined;
          } else {
            return v.questionAnswer || v.defaultQuestionAnswer || undefined;
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.standard-text {
  color: black !important;
}

.two-input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.two-input-group .input-group-prepend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 40px);
  position: relative;
  height: 40px;
}

.two-input-group .input-group-prepend input {
  border-radius: 6px 0 0 6px !important;
}

.two-input-group .input-group-append {
  z-index: 99;
}

.two-input-group .input-group-append input {
  border: 1px solid #dbdbdb;
  border-left: 0;
  padding: 0px 15px;
  border-radius: 0 6px 6px 0;
  width: 80px;
  height: 40px;
  outline: none;
  text-align: left;
}

.two-input-group .input-group-append input:focus {
  border-color: #be2530;
}
.two-input-group.input-group input {
  border: none;
}
.two-input-group.input-group .input-group-prepend input {
  border-radius: 6px 0 0 6px !important;
}
.input-group input.form-control {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px !important;
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  text-indent: 30px;
  border: 1px solid #dbdbdb;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
  height: 100%;
  margin-left: 0 !important;
}

::-webkit-datetime-edit-text {
  visibility: hidden;
}

input[type="date"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

::-webkit-datetime-edit-text {
  color: red;
  padding: 0;
}

input[type="date"] {
  padding: 0 10px;
  text-align: center;
  letter-spacing: 0.06em;
}

input[type="date"]::before {
  content: "__.__.____";
  position: absolute;
  left: 11px;
  display: flex;
  justify-content: center;
  color: #8f969a;
  letter-spacing: 0.1em;
}
</style>
