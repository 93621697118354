<template>
  <div
    class="modal fade consentToContactModal"
    :id="id"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex mb-2 justify-content-end">
            <i
              class="close-fa-fa-icon fas fa-times"
              data-bs-dismiss="modal"
            ></i>
          </div>
          <div class="title">
            <h2>Consent to Contact</h2>
          </div>
          <div class="content-desc">
            <p>
              By clicking "Continue”, you agree that
              <strong>{{ loCompany }}</strong> and its current and future
              affiliates, successors, and assignees may call/text you at the
              phone number(s) provided about <strong>{{ loCompany }}</strong
              >’s services and products, which may involve use of automated
              means and prerecorded/artificial voices. You do not need to
              consent as a condition of buying any property, goods or services.
              Message/data rates may apply.
            </p>
          </div>
          <div class="button-center button mt-4">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn m-auto btn-red"
            >
              <span>Close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ConsetToContactModal",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getCompanyName"]),

    loCompany() {
      return this.getCompanyName.replaceAll("-", " ");
    },
  },
};
</script>

<style scoped>
@import "./../../assets/css/xl-modal.css";
</style>