<template>
  <div
    :id="id"
    class="modal fade termsOfUseModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex mb-2 justify-content-end">
            <i
              class="close-fa-fa-icon fas fa-times"
              data-bs-dismiss="modal"
            ></i>
          </div>
          <div class="title">
            <h2>CANDID's Consumer Terms of Use (Platform)</h2>
          </div>
          <div class="content-desc">
            <p>
              <strong>EFFECTIVE DATE: August 17, 2021</strong>
            </p>
            <p>
              <strong>
                IMPORTANT NOTICE: DISPUTES ABOUT THESE TERMS AND THE CANDID
                PLATFORM ARE SUBJECT TO BINDING ARBITRATION AND A WAIVER OF
                CLASS ACTION RIGHTS AS DETAILED IN THE “MANDATORY ARBITRATION
                AND CLASS ACTION WAIVER” SECTION BELOW.
              </strong>
            </p>
            <p>
              <strong>
                PLEASE NOTE THAT YOUR USE OF AND ACCESS TO THE PLATFORM (DEFINED
                BELOW) ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE
                TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE PLATFORM
                IN ANY MANNER.
              </strong>
            </p>
            <p>
              Welcome to CandidApp LLC. (“CANDID”, “we”, “our”, or “us”). Please
              read on to learn the terms, rules, and restrictions (“Terms of
              Use” or “Terms”) that govern your use of our products, services,
              and platform (referred to collectively as the “CANDID” or
              "Platform"). For purposes of these Terms, “you” and “your” mean
              the Platform account creator, authorized signer, authorized
              representative, delegate, and/or other authorized Platform user,
              including co-applicant. The Platform allows users to provide
              information that banks, mortgage companies, and other lenders
              (“Financial Services Providers”) use to process loan applications
              and offer other financial services to you. CANDID is not a lender
              or loan broker, and does not make lending or credit decisions on
              behalf of Financial Services Providers. The Platform does not
              constitute an offer or solicitation to lend.
            </p>
            <p>
              These Terms of Use are a legal agreement between you and CANDID.
              The Terms include the provisions below, as well as those in the
              Privacy Policy. You acknowledge and agree that, by accessing or
              using the Platform or by uploading or posting any content to the
              Platform, you are indicating that you have read and understand,
              and agree to be bound by these Terms. If you do not agree to these
              Terms, then you have no right to access or use the Platform.
            </p>
            <p>
              We may, from time to time, modify these Terms, including the
              Privacy Policy. If so, we will post the updated Terms on the
              Platform. If you do not agree to, or cannot comply with, the
              modified Terms, you must stop using the Platform. The updated
              Terms will take effect after their posting and will apply on a
              going-forward basis, unless otherwise provided in a notice to you,
              and except as provided in the “Mandatory Arbitration and Class
              Action Waiver” section of these Terms. Your continued use of the
              Platform after any such update constitutes your binding acceptance
              of such changes.
            </p>
            <p>
              <u><strong>Joint Applicants</strong></u>
              . If you are using the Platform with another person to apply
              jointly for a loan or other financial services product, you and
              your co-applicant may choose to provide information for your
              application using a single joint account or multiple individual
              Platform account(s). If joint applicants choose to utilize a
              single Platform account, they will have access to all information
              supplied by Platform account users to complete that application.
              Such joint applicants will have the ability to view, print,
              transmit, save, and/or edit the information. Access may continue
              for as long as the joint applicants utilize the single Platform
              account in connection with the application process.
            </p>
            <p>
              If you have any questions, comments, or concerns regarding these
              Terms or the Platform, please contact us at info@candidcrm.com
            </p>
            <ol>
              <li>
                <p>Eligibility and Scope</p>
              </li>
            </ol>
            <p>
              <strong>Eligibility</strong>
              . To use the Platform you must be, and represent and warrant that
              you are, of legal age (18 years of age or older or otherwise of
              legal age in your jurisdiction, or, if you have parental consent,
              13 years of age) and competent. If you’re agreeing to these Terms
              on behalf of an organization, entity, or co-applicant, you
              represent and warrant that you are authorized to agree to these
              Terms on behalf of that organization, entity, or co-applicant and
              bind them to these Terms (in which case, the references to "you"
              and "your" in these Terms, except for in this sentence, refer to
              that organization, entity, or you and your co-applicant). If
              CANDID has previously prohibited you from accessing the Platform,
              you do not have permission to access the Platform.
            </p>
            <p>
              <strong>Restrictions</strong>
              . You expressly agree that if you are located in any member state
              that is part of the European Economic Area or any member state
              that has adopted the General Data Protection Regulation, you are
              not permitted to use the Platform and you are not permitted to
              provide any personal data to us.
            </p>
            <ol start="2">
              <li>
                <p>Account Registration; Account Use</p>
              </li>
            </ol>
            <p>
              <strong>Account Registration and Confidentiality</strong>
              . You may be required to sign up for a CANDID Platform account and
              to provide information such as your email address, an account
              password, and recovery phone number for creating and
              authenticating a Platform account (“CANDID Account Credentials”).
              If so, you agree to provide us with CANDID Account Credentials
              that are accurate, complete, and current. You may not use an email
              address or other identifier that you do not have the right to use,
              or another person’s email address or other identifier with the
              intent to impersonate that person. In addition, you agree that you
              will protect the security of your Platform account and password,
              and that you will not share your Platform account or password with
              anyone unless you are using the Platform jointly with co-applicant
              to apply for a financial services product.
            </p>
            <p>
              <strong>Unauthorized Account Use</strong>
              . You are responsible for any activity associated with your
              Platform account, and for notifying us at
              <a href="mailto:info@candidcrm.com">info@candidcrm.com</a> if you
              become aware of any unauthorized access to your Platform account.
              You understand and agree that we may require you to provide
              information to confirm your identity and help ensure the security
              of your Platform account. CANDID will not be liable for any loss,
              damages, liability, expenses or attorneys’ fees that you may incur
              as a result of someone else using your CANDID password or account,
              as a result of your use or misuse, with or without your knowledge,
              and regardless of whether you have or have not advised us of such
              unauthorized use. In such case, you will be liable for losses,
              damages, liability, expenses and attorneys’ fees incurred by
              CANDID or a third party due to someone else’s use of your Platform
              account.
            </p>
            <ol start="3">
              <li>
                <p>Technical Requirements</p>
              </li>
            </ol>
            <p>
              Use of the Platform may be available through a compatible mobile
              device with internet access and may require certain software. You
              agree that you are solely responsible for these requirements,
              including any applicable changes, updates, and fees associated
              with them, as well as for complying with the terms of your mobile
              device and telecommunications provider. CANDID MAKES NO WARRANTIES
              OR REPRESENTATIONS OF ANY KIND, EXPRESS, STATUTORY, OR IMPLIED AS
              TO (A) THE AVAILABILITY OF A TELECOMMUNICATION PLATFORM FROM YOUR
              PROVIDER OR ACCESS TO A TELECOMMUNICATION PLATFORM FROM YOUR
              PROVIDER AT ANY TIME OR FROM ANY LOCATION; (B) ANY LOSS, DAMAGE,
              OR SECURITY INTRUSION OF THE TELECOMMUNICATION PLATFORM FROM YOUR
              PROVIDER; AND (C) ANY DISCLOSURE OF INFORMATION TO THIRD PARTIES
              OR FAILURE TO TRANSMIT ANY DATA, COMMUNICATIONS, OR SETTINGS IN
              CONNECTION WITH A TELECOMMUNICATION PLATFORM FROM YOUR PROVIDER.
            </p>
            <ol start="4">
              <li>
                <p>Our Proprietary Rights</p>
              </li>
            </ol>
            <p>
              <strong>Intellectual Property Rights</strong>
              . The Platform is owned and operated by CANDID and contains
              material which is derived in whole or in part from material
              supplied by CANDID and our partners, as well as other sources, and
              is protected by United States copyright laws, international treaty
              provisions, trademarks, service marks and other intellectual
              property laws. The Platform is also protected as a collective work
              or compilation under U.S. copyright and other law and treaties.
              You agree to abide by all applicable copyright and other laws, as
              well as any additional copyright notices or restrictions contained
              in the Platform. You acknowledge that the Platform has been
              developed, compiled, prepared, revised, selected, and arranged by
              CANDID and others through the application of methods and standards
              of judgment developed and applied through the expenditure of
              substantial time, effort, and money and constitute valuable
              intellectual property of CANDID and such others. You agree to
              protect the proprietary rights of CANDID and all others having
              rights in the Platform during and after the term of these Terms
              and to comply with all reasonable written requests made by CANDID
              or our suppliers and licensors of content or otherwise
              (“Suppliers”) to protect their and others’ contractual, statutory,
              and common law rights in the Platform. You agree to notify CANDID
              immediately upon becoming aware of any unauthorized access or use
              of the Platform by any individual or entity or of any claim that
              the Platform infringes upon any copyright, trademark, or other
              contractual, statutory, or common law rights. All present and
              future rights in and to trade secrets, patents, copyrights,
              trademarks, service marks, know-how, and other proprietary rights
              of any type under the laws of any governmental authority, domestic
              or foreign, including, without limitation rights in and to all
              applications and registrations relating to the Platform shall, as
              between you and CANDID, at all times be and remain the sole and
              exclusive property of CANDID.
            </p>
            <p>
              <strong>Use of Marks</strong>
              . You may not use any of CANDID’s trademarks, trade names, service
              marks, copyrights, or logos in any manner which creates the
              impression that such items belong to or are associated with you
              or, except as otherwise provided herein, are used with CANDID’s
              consent. You acknowledge that you have no ownership rights in or
              to any such items.
            </p>
            <ol start="5">
              <li>
                <p>Authorization to Retrieve and Use Information You Provide</p>
              </li>
            </ol>
            <p>
              <strong>Submitted Information</strong>
              . By providing information about yourself, including CANDID
              Account Credentials and third-party account credentials such as
              usernames, passwords, PINs, and other log-in information
              (“Submitted Information"), you are licensing such Submitted
              Information to CANDID and our agents for the purpose of providing
              the Platform. You represent that you are entitled to provide the
              Submitted Information to CANDID to use for the purpose of
              providing the Platform, without any obligation by CANDID to pay
              any fees or be subject to any restrictions or limitations. You
              hereby authorize and permit CANDID to use and store the Submitted
              Information for the purpose of providing the Platform, including
              configuring the Platform to be compatible with third-party
              websites that enable CANDID to provide the Platform. CANDID will
              store the Submitted Information in encrypted form, and will use
              the Submitted Information in accordance with these Terms and our
              Privacy Policy.
            </p>
            <p>
              <strong>Account Information</strong>
              . Where available, for the purpose of completing your application,
              CANDID and our agents may access and retrieve, or enable you to
              initiate access to and retrieval of, account information and other
              data and documents (“Account Information”) from online accounts at
              third parties, including financial institutions, investing
              platforms, payroll providers, and tax preparation platforms, among
              others, in accordance with our Privacy Policy. This information
              may also include credit documentation and other information for
              verifying and/or re-verifying your assets, income, and employment.
              When available, Account Information may be prepopulated in the
              Platform. Please note that when displayed through the Services,
              Account Information does not represent an official record of your
              account with third parties. Account Information is only as recent
              as the time shown, and CANDID does not have an obligation to keep
              such data up-to-date at all times. Account Information may be more
              up-to-date when obtained directly from the relevant third-party
              sites. CANDID may also store Account Information and use the
              Submitted Information to update and maintain Account Information
              for future use.
            </p>
            <p>
              As part of the Platform, we also transmit Submitted Information
              and Account Information to your Financial Services Provider,
              relevant parties specifically authorized to receive the
              information on behalf of the Financial Services Provider, and
              potentially your Financial Services Providers’
              successors-in-interest. By using the Platform, you expressly
              authorize CANDID and our agents to access, retrieve, and transmit
              Submitted Information and Account Information as part of the
              Platform, on your behalf, and as your agent.
            </p>
            <p>
              As part of these Terms and solely for the purpose of providing the
              Platform, you grant CANDID and our agents a limited power of
              attorney, and appoint CANDID and our agents as your
              attorney-in-fact and agent, to access third-party websites, use
              Submitted Information you provide, and retrieve Account
              Information, with the full power and authority to do and perform
              each task necessary in connection with such activities as you
              could do in person. YOU ACKNOWLEDGE AND AGREE THAT WHEN CANDID AND
              OUR AGENTS ARE ACCESSING AND RETRIEVING ACCOUNT INFORMATION FROM
              THIRD-PARTY WEBSITES, CANDID AND OUR AGENTS ARE ACTING AS YOUR
              AGENT, AND NOT AS THE AGENT OF OR ON BEHALF OF THE THIRD PARTY
              THAT OPERATES THE THIRD-PARTY WEBSITE. CANDID cannot always
              foresee or anticipate technical or other difficulties that may
              result in failure to obtain data or in loss of data. Further,
              CANDID does not assume responsibility for the timeliness,
              accuracy, deletion, or non-delivery of, or failure to store, any
              data.
            </p>
            <p>
              <strong>
                Right to Provide Submitted Information and Account Information
              </strong>
              . You represent and warrant to us that you have all rights to the
              Submitted Information and Account Information that you provide to
              us via the Platform. You are responsible for the accuracy and
              completeness of the Submitted Information and Account Information
              you provide. You, and not CANDID, are liable in the event that
              such Submitted Information and/or Account Information is not
              accurate or complete. You agree that you will indemnify, defend,
              and hold harmless CANDID for all claims resulting from your
              unauthorized access to Submitted Information and Account
              Information, or for any inaccurate or incomplete Submitted
              Information and/or Account Information that you provide us. We
              reserve the right, at our own expense, to assume the exclusive
              defense and control of such disputes, and in any event you will
              cooperate with us in asserting any available defenses.
            </p>
            <p>
              <strong>Feedback</strong>
              . Feedback you provide to us about your user experience with the
              Platform, by email or through other mediums, excluding personally
              identifiable consumer information, shall be deemed to be
              non-confidential, and we assume no obligation to protect such
              information from disclosure or provide compensation to you. The
              submission of such information to us shall in no way prevent the
              purchase, manufacture, or use of similar products, services,
              plans, and ideas by us for any purpose whatsoever, and we shall be
              free to reproduce, use, disclose, and distribute the information
              to others without restriction.
            </p>
            <ol start="6">
              <li>
                <p>License and Prohibited Conduct</p>
              </li>
            </ol>
            <p>
              <strong>Your License</strong>
              . Subject to your compliance with these Terms, we grant you a
              limited, non-exclusive, non-sublicensable, non-transferable, and
              revocable right to access and use the Platform only for your own
              internal, personal, or non-commercial use, and only in a manner
              that complies with all local, state, national, and foreign laws,
              treaties, regulations, and other legal requirements that apply to
              you or your use of the Platform, including those relating to data
              security and data privacy. If your use of the Platform is
              prohibited by applicable law, then you do not have authorization
              to use the Platform. CANDID is not responsible for unlawful uses
              of the Platform.
            </p>
            <p>
              <strong>License You Grant CANDID</strong>
              . Anything you upload, share, store, or otherwise provide,
              including Submitted Information and Account Information, is
              content that you provide. In order to display your content on the
              Platform, you grant us certain rights in that content. As such,
              you hereby grant CANDID a license to translate, modify (for
              technical purposes, such as making your content viewable on a
              mobile device as well as a computer), reproduce, and otherwise act
              with respect to such content, in each case to enable us to enhance
              the operation of the Platform, or to provide products or services
              to you through or in connection with the Platform. You also grant
              CANDID a license to aggregate and/or de-identify the information
              you provide, which CANDID may use for improving the Platform and
              developing business analytics, as discussed in our Privacy Policy.
              This is a license only; your ownership in the content you provide
              is not affected. You agree that the licenses you grant are
              royalty-free, perpetual, sub-licensable, irrevocable, and
              worldwide. In addition, please note that the foregoing licenses
              are subject to our Privacy Policy.
            </p>
            <p>
              <strong>Prohibited Uses</strong>
              . Use of the Platform for any illegal purpose, or any other
              purpose not expressly permitted in these Terms, is strictly
              prohibited. Without limitation, you will not:
            </p>
            <ol></ol>
            <ul>
              <li>
                <p>
                  Use the Platform to harass, abuse, or threaten any other
                  person;
                </p>
              </li>
              <li>
                <p>
                  Provide information that is unlawful, harmful, tortious,
                  defamatory, libelous, or invasive of another's privacy;
                </p>
              </li>
              <li>
                <p>
                  Use the Platform commercially, for benchmarking, or to compile
                  information for a product or service;
                </p>
              </li>
              <li>
                <p>
                  Copy, download (other than for personal use, or as otherwise
                  expressly permitted by these Terms), modify, distribute, post,
                  transmit, display, perform, reproduce, broadcast, duplicate,
                  publish, republish, upload, license, reverse engineer, create
                  derivative works from, or offer for sale any content or other
                  information contained on or obtained from or through the
                  Platform by any means except as provided for in these Terms or
                  with the prior written consent of CANDID;
                </p>
              </li>
              <li>
                <p>
                  Scrape, access, monitor, index, frame, link, or copy any
                  content or information on the Platform by accessing the
                  Platform in an automated way, using any robot, spider,
                  scraper, web crawler, or any other method of access other than
                  manually accessing the publicly available portions of the
                  Platform through a browser or accessing the Platform through
                  any approved API;
                </p>
              </li>
              <li>
                <p>
                  Violate the restrictions in any robot exclusion headers of the
                  Platform, if any, or bypass or circumvent other measures
                  employed to prevent or limit access to the Platform;
                </p>
              </li>
              <li>
                <p>
                  Post material that advocates illegal activity or discusses
                  illegal activities with the intent to commit them (in either
                  case as determined by CANDID in our sole discretion);
                </p>
              </li>
              <li>
                <p>
                  Upload or otherwise make available any material that contains
                  any software, device, instructions, computer code, files,
                  programs and/or other content or feature that is designed to
                  interrupt, destroy, or limit the functionality of any computer
                  software or hardware or telecommunications equipment
                  (including without limitation any time bomb, virus, software
                  lock, worm, self-destruction, drop-device, malicious logic,
                  Trojan horse, trap door, "disabling," "lock out," or
                  "metering" device, or any malicious code);
                </p>
              </li>
              <li>
                <p>
                  Attempt to disable, overburden, or impair the proper working
                  of the Platform;
                </p>
              </li>
              <li>
                <p>
                  Use the Platform to distribute any other party's intellectual
                  property unless you have the right to do so, or remove or
                  alter any copyright, trademark, or other proprietary notice
                  contained on the Platform;
                </p>
              </li>
              <li>
                <p>
                  Make available trade secrets or other confidential or
                  proprietary information, or provide any material that you do
                  not have a right to make available under any law or under
                  contractual or fiduciary relationships, including but not
                  limited to insider information, or confidential or proprietary
                  information learned or disclosed as part of employment
                  relationships or under non-disclosure agreements;
                </p>
              </li>
              <li>
                <p>
                  Falsely state or otherwise misrepresent your affiliation with
                  a person or entity, or impersonate any person or entity;
                </p>
              </li>
              <li>
                <p>
                  Frame, inline link, or similarly display the Platform or any
                  portion thereof;
                </p>
              </li>
              <li>
                <p>
                  Violate these Terms or any guidelines or policies posted by
                  CANDID;
                </p>
              </li>
              <li>
                <p>
                  Facilitate violations of these Terms or the Privacy Policy or
                  any guidelines or policies posted by CANDID; and/or
                </p>
              </li>
              <li>
                <p>
                  Interfere with any other party’s use and enjoyment of the
                  Platform.
                </p>
              </li>
            </ul>
            <p>
              CANDID reserves the right, but not the obligation, in our sole and
              absolute discretion, to remove any information provided by you,
              block access to the Platform, and/or deactivate your Platform
              account for violation of these Terms.
            </p>
            <ol start="7">
              <li>
                <p>Legal Compliance</p>
              </li>
            </ol>
            <p>
              You acknowledge, consent, and agree that CANDID may access,
              preserve, and disclose content you provide us if required to do so
              by law or in a good faith belief that such access, preservation,
              or disclosure is permitted by our Privacy Policy or reasonably
              necessary or appropriate for any of the following reasons: (1) to
              comply with legal process (e.g., a subpoena, court order, or
              warrant); (2) to enforce these Terms; (3) to respond to claims
              that any content violates the rights of third parties; (4) to
              protect the rights, property, or personal safety of CANDID, our
              agents and affiliates, our users, and the public; or (5) to
              address your requests.
            </p>
            <p>
              In addition, please note that CANDID is not in the business of
              providing professional services or legal, tax, or other advice
              through or relating to this Platform. Please consult your
              Financial Services Providers and/or professional advisors with
              questions relating to your use of the Platform.
            </p>
            <ol start="8">
              <li>
                <p>WARRANTIES AND DISCLAIMERS</p>
              </li>
            </ol>
            <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT:</p>
            <p>
              YOUR USE OF THE PLATFORM IS SOLELY AT YOUR OWN RISK. CANDID DOES
              NOT MAKE ANY REPRESENTATIONS OR WARRANTIES CONCERNING ANY CONTENT
              CONTAINED IN OR ACCESSED THROUGH THE PLATFORM, AND WE WILL NOT BE
              RESPONSIBLE OR LIABLE FOR THE ACCURACY, COPYRIGHT COMPLIANCE,
              LEGALITY, OR DECENCY OF MATERIAL CONTAINED IN OR ACCESSED THROUGH
              THE PLATFORM. WE MAKE NO REPRESENTATIONS OR WARRANTIES REGARDING
              SUGGESTIONS OR RECOMMENDATIONS OF PRODUCTS OR SERVICES OFFERED OR
              PURCHASED THROUGH THE PLATFORM. PRODUCTS AND SERVICES OFFERED OR
              PURCHASED (WHETHER OR NOT FOLLOWING SUCH RECOMMENDATIONS AND
              SUGGESTIONS) THROUGH THE PLATFORM ARE PROVIDED "AS IS" AND WITHOUT
              ANY WARRANTY OF ANY KIND FROM CANDID OR OTHERS (UNLESS, WITH
              RESPECT TO SUCH OTHERS ONLY, PROVIDED EXPRESSLY AND UNAMBIGUOUSLY
              IN WRITING BY A DESIGNATED THIRD PARTY FOR A SPECIFIC PRODUCT OR
              SERVICE). THE PLATFORM IS PROVIDED ON AN "AS-IS" BASIS, WITHOUT
              WARRANTIES OR ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
              WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
              FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE
              PLATFORM WILL BE UNINTERRUPTED OR ERROR-FREE.
            </p>
            <p>
              ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE PLATFORM
              IS DOWNLOADED AND USED AT YOUR SOLE DISCRETION AND RISK, AND YOU
              WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM,
              MOBILE DEVICE, SOFTWARE, TECHNOLOGY, OR LOSS OF DATA THAT RESULTS
              FROM THE DOWNLOAD OR USE OF ANY SUCH CONTENT.
            </p>
            <p>
              NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
              FROM CANDID OR THROUGH OR FROM THE PLATFORM SHALL CREATE ANY
              WARRANTY.
            </p>
            <p>
              CANDID MAKES NO REPRESENTATION, WARRANTY, GUARANTEE, OR PROMISE
              THAT THE PRODUCTS, SERVICES, OR PLATFORM WILL MEET YOUR
              REQUIREMENTS OR ACHIEVE ANY PARTICULAR RESULTS. DECISIONS MADE BY
              FINANCIAL SERVICES PROVIDERS AND OTHERS AS A RESULT OF THE
              INFORMATION COMPILED FROM AND ABOUT YOU THROUGH THE PLATFORM ARE
              THE SOLE RESPONSIBILITY OF SUCH FINANCIAL SERVICES PROVIDERS AND
              OTHERS, AND ARE SUBJECT TO THE TERMS AND CONDITIONS OF SUCH
              FINANCIAL SERVICES PROVIDERS AND OTHERS.
            </p>
            <ol start="9">
              <li>
                <p>LIMITATION OF LIABILITY</p>
              </li>
            </ol>
            <p>
              TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO
              CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT
              LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL
              CANDID BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY
              INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY
              KIND, INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK
              STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION;
              (B) THE DECISIONS MADE BY FINANCIAL SERVICES PROVIDERS AND OTHERS
              AS A RESULT OF THE INFORMATION COMPILED FROM AND ABOUT YOU THROUGH
              THE PLATFORM; OR, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF
              THE GREATER OF (I) $100 OR (II) THE AMOUNTS PAID BY YOU TO CANDID
              IN CONNECTION WITH THE PLATFORM IN THE TWELVE (12) MONTH PERIOD
              PRECEDING THIS APPLICABLE CLAIM.
            </p>
            <p>
              In the event that you have a dispute with any third party, you
              agree that CANDID is under no obligation to become involved on
              your behalf. You release CANDID, and our officers, employees,
              agents, and successors from claims, demands, and damages of every
              kind or nature, known or unknown, suspected or unsuspected,
              disclosed or undisclosed, arising out of or in any way relating to
              such disputes and/or the Platform. If you are a California
              resident, you hereby waive California Civil Code §1542, which
              says: "A general release does not extend to claims which the
              creditor does not know or suspect to exist in his favor at the
              time of executing the release, which if known by him must have
              materially affected his settlement with the debtor." This release
              includes the criminal acts of others.
            </p>
            <ol start="10">
              <li>
                <p>Exclusions and Limitations</p>
              </li>
            </ol>
            <p>
              Some jurisdictions do not allow the exclusion of certain
              warranties or the limitation or exclusion of liability for
              incidental or consequential damages such as above in Sections 8
              and 9. Accordingly, some of the above limitations may not apply to
              you. If you are a New Jersey resident, or a resident of another
              state that permits the exclusion of these warranties and
              liabilities, the limitations in Sections 8 and 9 specifically do
              apply to you.
            </p>
            <ol start="11">
              <li>
                <p>Indemnity</p>
              </li>
            </ol>
            <p>
              YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD CANDID AND OUR RESPECTIVE
              OFFICERS, DIRECTORS, EMPLOYEES, MEMBERS, SHAREHOLDERS, AND
              REPRESENTATIVES (AND ALL SUCCESSORS AND ASSIGNS OF ANY OF THE
              FOREGOING) HARMLESS FROM AND AGAINST ANY CLAIM OR DEMAND,
              INCLUDING, WITHOUT LIMITATION, REASONABLE ATTORNEYS' FEES AND
              DISBURSEMENTS, MADE IN CONNECTION WITH OR ARISING OUT OF YOUR
              VIOLATION OF THESE TERMS OR OUR PRIVACY POLICY, AND/OR YOUR
              SUBMISSION, POSTING, OR TRANSMISSION OF CONTENT TO THE PLATFORM.
              WE RESERVE THE RIGHT, AT OUR OWN EXPENSE, TO ASSUME THE EXCLUSIVE
              DEFENSE AND CONTROL OF SUCH DISPUTES, AND IN ANY EVENT, YOU WILL
              COOPERATE WITH US IN ASSERTING ANY AVAILABLE DEFENSES.
            </p>
            <ol start="12">
              <li>
                <p>Third Party Links and Platform</p>
              </li>
            </ol>
            <p>
              CANDID has no control over, and assumes no responsibility for, the
              content, accuracy, privacy policies, or practices of, or opinions
              expressed in, any third-party websites or by any third party that
              you interact with through the Platform. In addition, CANDID will
              not and cannot monitor, verify, censor, or edit the content of any
              third-party website or service. By using the Platform, you release
              and hold us harmless from any and all liability arising from your
              use of any third-party website or service, and you acknowledge and
              agree that the terms and privacy policies of such third parties
              govern your interactions with and use of such websites and
              services.
            </p>
            <ol start="13">
              <li>
                <p>Modification and Termination</p>
              </li>
            </ol>
            <p>
              <u><strong>Modification of Platform</strong></u>
              . CANDID reserves the right at any time to modify or discontinue,
              temporarily or permanently, the Platform (or any part thereof),
              with or without notice. You agree that CANDID shall not be liable
              to you or any third party for any modification, suspension, or
              discontinuance of the Platform
            </p>
            <p>
              <strong>Termination</strong>
              <u>.</u>
              These Terms are effective unless and until terminated by you or
              us. We may, in our sole and absolute discretion, deny you access
              to all or part of the Platform at any time for any or no reason at
              all, with or without notice to you. Grounds for such termination
              shall include, but not be limited to, (a) breaches or violations
              of these Terms or other agreements, (b) requests by law
              enforcement or government agencies, (c) discontinuance or material
              modification of the Platform (or any part thereof), (d) unexpected
              technical or security issues or problems, (e) extended periods of
              inactivity, (f) activities related to protecting the rights,
              property, or safety of CANDID, our agents and affiliates, or our
              users and the public, or (g) if you provide any information,
              including CANDID Account Credentials or Submitted Information,
              that is false, inaccurate, out-of-date, or incomplete. If we
              terminate your right to access the Platform, these Term will
              terminate and all rights you have to access the Platform will
              immediately terminate; however, certain provisions of these Terms
              will still apply post-termination, including without limitation,
              the “Mandatory Arbitration and Class Action Waiver” provisions.
              Termination of your Platform account may also include, at CANDID’s
              sole discretion, the deletion of your Platform account and/or
              content.
            </p>
            <ol start="14">
              <li>
                <p>MANDATORY ARBITRATION AND CLASS ACTION WAIVER</p>
              </li>
            </ol>
            <p>
              <strong>
                PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL
                RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.
              </strong>
            </p>
            <p>
              <u><strong>Application</strong></u>
              . You and CANDID agree that these Terms affect interstate commerce
              and that the Federal Arbitration Act governs the interpretation
              and enforcement of these arbitration provisions. This Section 15
              is intended to be interpreted broadly and governs any and all
              disputes between us including but not limited to claims arising
              out of or relating to any aspect of the relationship between us,
              whether based in contract, tort, statute, fraud,
              misrepresentation, or any other legal theory; claims that arose
              before these Terms or any prior agreement (including, but not
              limited to, claims related to advertising); and claims that may
              arise after the termination of these Terms. The only disputes
              excluded from this broad prohibition are the litigation of certain
              intellectual property and small court claims, as provided below.
            </p>
            <p>
              <u><strong>Initial Dispute Resolution</strong></u>
              . Most disputes can be resolved without resort to arbitration. If
              you have any dispute with us, you agree that before taking any
              formal action, you will contact us at
              <a href="mailto:info@candidcrm.com">info@candidcrm.com</a>, and
              provide a brief written description of the dispute and your
              contact information (including your username, if your dispute
              relates to a Platform account). Except for intellectual property
              and small claims court claims, the parties agree to use their best
              efforts to settle any dispute, claim, question, or disagreement
              directly through consultation with each other, and good faith
              negotiations shall be a condition to either party initiating a
              lawsuit or arbitration.
            </p>
            <p>
              <u><strong>Binding Arbitration</strong></u>
              . If the parties do not reach an agreed-upon solution within a
              period of thirty (30) days from the time informal dispute
              resolution is initiated under the “Initial Dispute Resolution”
              provision above, then either party may initiate binding
              arbitration as the sole means to resolve claims, (except as
              provided in section 15.7 below) subject to the terms set forth
              below. Specifically, all claims arising out of or relating to
              these Terms (including the Terms’ formation, performance, and
              breach), the parties' relationship with each other, and/or your
              use of CANDID shall be finally settled by binding arbitration
              administered by JAMS in accordance with the JAMS Streamlined
              Arbitration Procedure Rules for claims that do not exceed $250,000
              and the JAMS Comprehensive Arbitration Rules and Procedures for
              claims exceeding $250,000 in effect at the time the arbitration is
              initiated, excluding any rules or procedures governing or
              permitting class actions.
            </p>
            <p>
              <u><strong>Arbitrator’s Powers</strong></u>
              . The arbitrator, and not any federal, state, or local court or
              agency, shall have exclusive authority to resolve all disputes
              arising out of or relating to the interpretation, applicability,
              enforceability, or formation of these Terms, including, but not
              limited to any claim that all or any part of these Terms is void
              or voidable, whether a claim is subject to arbitration, or the
              question of waiver by litigation conduct. The arbitrator shall be
              empowered to grant whatever relief would be available in a court
              under law or in equity. The arbitrator's award shall be written
              and shall be binding on the parties and may be entered as a
              judgment in any court of competent jurisdiction.
            </p>
            <p>
              To the extent the filing fee for the arbitration exceeds the cost
              of filing a lawsuit, CANDID will pay the additional cost. If the
              arbitrator finds the arbitration to be non-frivolous, CANDID will
              pay the fees invoiced by JAMS, including filing fees and
              arbitrator and hearing expenses. You are responsible for your own
              attorneys' fees unless the arbitration rules and/or applicable law
              provide otherwise.
            </p>
            <p>
              The parties understand that, absent this mandatory arbitration
              provision, they would have the right to sue in court and have a
              jury trial. They further understand that, in some instances, the
              costs of arbitration could exceed the costs of litigation and the
              right to discovery may be more limited in arbitration than in
              court. Arbitration may take place in the county where you reside
              at the time of filing, unless you and we both agree to another
              location or telephonic arbitration.
            </p>
            <p>
              <u><strong>Class Action Waiver</strong></u>
              . The parties further agree that the arbitration shall be
              conducted in the party’s respective individual capacities only and
              not as a class action or other representative action, and the
              parties expressly waive their right to file a class action or seek
              relief on a class basis. YOU AND CANDID AGREE THAT EACH MAY BRING
              CLAIMS AGAINST THE OTHER ONLY IN YOUR OR OUR INDIVIDUAL CAPACITY,
              AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
              REPRESENTATIVE PROCEEDING. If any court or arbitrator determines
              that the class action waiver set forth in this paragraph is void
              or unenforceable for any reason, or that an arbitration can
              proceed on a class basis, then the arbitration provisions set
              forth above shall be deemed null and void in their entirety and
              the parties shall be deemed to have not agreed to arbitrate
              disputes.
            </p>
            <p>
              <u>
                <strong>
                  Exception: Litigation of Intellectual Property and Small
                  Claims Court Claims
                </strong>
              </u>
              . Notwithstanding the parties' decision to resolve all disputes
              through arbitration, either party may bring enforcement actions,
              validity determinations, or claims arising from or relating to
              theft, piracy or unauthorized use of intellectual property in
              state or federal court or in the U.S. Patent and Trademark Office
              to protect its intellectual property rights ("intellectual
              property rights" means patents, copyrights, moral rights,
              trademarks, and trade secrets, but not privacy or publicity
              rights). Either party may also seek relief in a small claims court
              for disputes or claims within the scope of that court's
              jurisdiction.
            </p>
            <p>
              <u><strong>30-Day Right to Opt Out</strong></u>
              . You have the right to opt out and not be bound by the
              arbitration and class action waiver provisions set forth above by
              sending written notice of your decision to opt out to at
              <a href="mailto:info@candidcrm.com">info@candidcrm.com</a> with
              the subject line, "ARBITRATION AND CLASS ACTION WAIVER OPT-OUT."
              The notice must be sent within thirty (30) days of Platform
              account creation. Otherwise you shall be bound to arbitrate
              disputes in accordance with the terms of these paragraphs. If you
              opt out of these arbitration provisions, CANDID also will not be
              bound by them.
            </p>
            <p>
              <u><strong>Changes to This Section</strong></u>
              . CANDID will provide thirty (30) days' notice of any changes to
              this section by posting on the Platform. Amendments will become
              effective thirty (30) days after they are posted on the Platform
              or sent to you by email. Changes to this section will otherwise
              apply prospectively only to claims arising after the thirtieth
              (30th) day. If a court or arbitrator decides that this subsection
              on "Changes to This Section" is not enforceable or valid, then
              this subsection shall be severed from the section entitled
              “Mandatory Arbitration and Class Action Waiver”, and the court or
              arbitrator shall apply the first “Mandatory Arbitration and Class
              Action Waiver” section in existence after you began using the
              Platform.
            </p>
            <p>
              <u><strong>Survival</strong></u>
              . This “Mandatory Arbitration and Class Action Waiver” section
              shall survive any termination of your use of the Platform.
            </p>
            <ol start="15">
              <li>
                <p>Controlling Law and Severability</p>
              </li>
            </ol>
            <p>
              These Terms will be interpreted in accordance with the laws of the
              State of California, without regard to its conflict-of-law
              provisions. For all purposes of these Terms, the parties consent
              to exclusive jurisdiction and venue in the state or federal courts
              located in, respectively, San Francisco County, California, or the
              Northern District of California. If any part of these Terms is
              considered invalid, it shall be enforced as effectively as
              possible while all other provisions remain in full effect.
            </p>
            <ol start="16">
              <li>
                <p>General Terms</p>
              </li>
            </ol>
            <p>
              <u><strong>Force Majeure</strong></u>
              . Under no circumstances shall CANDID or our licensors or
              suppliers be held liable for any delay or failure in performance
              resulting directly or indirectly from an event beyond our
              reasonable control.
            </p>
            <p>
              <u><strong>No Waiver; Severability</strong></u>
              . No waiver of any term of these Terms will be binding unless in
              writing. No waiver of any term of these Terms will be deemed a
              further or continuing waiver of such term or any other term, and
              the failure of CANDID to exercise or enforce any right or remedy
              in these Terms does not waive that right or remedy. If an
              arbitrator or a court of competent jurisdiction finds any
              provision of these Terms to be invalid, the parties agree that the
              court should endeavor to give effect, to the maximum extent
              permitted by law, to the parties’ intentions as reflected in the
              provision, and the other provisions of these Terms will remain in
              full force and effect.
            </p>
            <p>
              <u><strong>Third-Party Beneficiaries</strong></u>
              . You agree that, except as otherwise expressly provided in these
              Terms, there shall be no third-party beneficiaries to these Terms.
            </p>
            <p>
              <u><strong>Statute of Limitations</strong></u>
              . Except for residents of New Jersey, you agree that regardless of
              any statute or law to the contrary, any claim or cause of action
              arising out of or related to the use of the Platform and/or these
              Terms must be filed within one (1) year after such claim or cause
              of action arose or be forever barred.
            </p>
            <p>
              <u><strong>Miscellaneous</strong></u>
              . These Terms (and all terms and conditions incorporated herein)
              constitute the entire agreement between you and CANDID and govern
              your use of the Platform, products, and services provided by
              CANDID, and supersede any prior agreements between you and CANDID
              on the subject matter. These Terms, and any rights or licenses
              granted hereunder, may not be assigned or delegated by you. These
              Terms, and any rights or licenses granted hereunder, may be
              assigned or delegated by CANDID without restriction. These Terms
              bind and inure to the benefit of each party and the party’s
              successors and permitted assigns. These Terms may not be modified
              by an oral statement by a representative of CANDID. No agency,
              partnership, joint venture or employee-employer relationship is
              intended or created by these Terms. You agree that any agreements
              made by and between you and us in electronic form are as legally
              binding as if made in physical written form. These Terms will not
              be construed against the drafter. The section titles in these
              Terms are for convenience only and have no legal or contractual
              effect.
            </p>
            <p>
              <u><strong>Notices</strong></u>
              . We may deliver notice to you by email, posting a notice on the
              Platform, or any other method we choose and such notice will be
              effective on dispatch. If you give notice to us, it will be
              effective when received and you must use the following email
              address:
              <a href="mailto:info@candidcrm.com">info@candidcrm.com</a>.
            </p>
            <ol start="17">
              <li>
                <p>Questions</p>
              </li>
            </ol>
            <p>
              If you have any questions about these Terms, please contact us by
              email at
              <a href="mailto:info@candidcrm.com">info@candidcrm.com</a>.
            </p>
          </div>
          <div class="button-center button mt-4">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn m-auto btn-red"
            >
              <span>Close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsOfUseModal",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
@import "./../../assets/css/xl-modal.css";
</style>